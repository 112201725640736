<template>
  <div>
    <v-row>
      <v-col cols="12">
        <muy ref="MuyComponent"></muy>
        <live ref="LiveComponent" class="mt-4"></live>
        <match ref="MatchComponent" class="mt-4"></match>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Muy from './Muy';
import Live from './Live';
import Match from './Match';
export default {
  components: {
    Muy,
    Live,
    Match,
  },
  data() {
    return {
      req: {},
      num: 10,
      intervalid1: "",
      loading: true,
      items:[],
    };
  },
  methods: {
    GetData(){
      clearInterval(this.intervalid1);
      this.$store.commit("SET_OVERLAY", true);
            this.req
                .get(this.$store.getters.base_api + "GetRound")
                .then((response) => {
                    if(response.data.date!=''){
                      this.$refs.MuyComponent.date = response.data.date;
                      this.$refs.LiveComponent.date = response.data.date;
                      this.$refs.MatchComponent.date = response.data.date;
                      this.$refs.MuyComponent.GetData();
                      this.$refs.LiveComponent.GetData();
                      this.$refs.MatchComponent.GetData();
                    }else{
                      setTimeout(function () { 
                        this.GetData();
                      }.bind(this), 10000)
                    }
                })
                .catch((error) => {
                    console.log(error)
                    setTimeout(function () { 
                      this.GetData();
                    }.bind(this), 10000)
                });
    },
  },
  mounted() {
    this.req = this.$store.getters.req;
    //console.log(this.CountDown)
    this.GetData();
  },
};
</script>
<style type="text/css">
  .v-table tr:hover:not(.v-table__expanded__content) {
    background: #8396AF !important;
    color:#FFF !important;
  }
</style>
