<template>
  <div>
    <v-card class="mx-auto" outlined>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="text-center">
            <span class="text-h3">ราคามวย วันที่ {{ date }}</span>
          </v-col>
          <v-col cols="12" class="text-center pt-0">
            <v-btn
              outlined
              color="indigo"
              small
              :loading="loading"
              :disabled="loading"
              @click="num=0"
            >
              ({{ num }}) เรียกซ้ำ
            </v-btn>
          </v-col>
        </v-row>
        <v-simple-table dense style="background: #8396AF;color:#FFF;" class="match_table mt-4">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left"></th>
                <th class="text-center">คู่แข่งขัน</th>
                <th class="text-center">บอล</th>
                <th class="text-center">เหย้า</th>
                <th class="text-center">เยือน</th>
                <th class="text-center">Goal</th>
                <th class="text-center">สูง</th>
                <th class="text-center">ต่ำ</th>
                <th class="text-center">บอล</th>
                <th class="text-center">เหย้า</th>
                <th class="text-center">เยือน</th>
                <th class="text-center">Goal</th>
                <th class="text-center">สูง</th>
                <th class="text-center">ต่ำ</th>
              </tr>
            </thead>
            <tbody v-for="(item,k) in items" v-bind:key="item.id" :class="(k%2=='0' ? 'bg2':'')">
                <tr v-if="item.league_name!=''" style="background: #000;color: #ffeb00;" class="league">
                  <td colspan="14" class="text-center">{{ item.league_name }}</td>
                </tr>
                <tr>
                  <td rowspan="2" style="vertical-align: top;border-bottom: 1px solid #74859c;color: red;">
                    {{ item.time_start }}
                  </td>
                  <td rowspan="2" style="vertical-align: top;border-bottom: 1px solid #74859c;">
                    <span :class="(item.home_hdp==1 ? 'hdp':'hdp_')">{{ item.matche_home }}</span><br/>
                    <span :class="(item.home_hdp==0 ? 'hdp':'hdp_')">{{ item.matche_away }}</span>
                  </td>
                  <td class="text-center">{{ CheckNum(item.hdp_f,2) }}</td>
                  <td class="text-center">{{ CheckNum(item.hdp_f_a,3) }}</td>
                  <td class="text-center">{{ CheckNum(item.hdp_f_b,3) }}</td>
                  <td class="text-center">{{ CheckNum(item.ou_f,2) }}</td>
                  <td class="text-center">{{ CheckNum(item.ou_f_a,3) }}</td>
                  <td class="text-center">{{ CheckNum(item.ou_f_b,3) }}</td>

                  <td class="text-center">{{ CheckNum(item.hdp_h,2) }}</td>
                  <td class="text-center">{{ CheckNum(item.hdp_h_a,3) }}</td>
                  <td class="text-center">{{ CheckNum(item.hdp_h_b,3) }}</td>
                  <td class="text-center">{{ CheckNum(item.ou_step_h,2) }}</td>
                  <td class="text-center">{{ CheckNum(item.ou_step_h_a,3) }}</td>
                  <td class="text-center">{{ CheckNum(item.ou_step_h_b,3) }}</td>
                </tr>

                <tr>
                  <td class="text-center">{{ CheckNum(item.hdp_f_2,2) }}</td>
                  <td class="text-center">{{ CheckNum(item.hdp_f_a_2,3) }}</td>
                  <td class="text-center">{{ CheckNum(item.hdp_f_b_2,3) }}</td>
                  <td class="text-center">{{ CheckNum(item.ou_f_2,2) }}</td>
                  <td class="text-center">{{ CheckNum(item.ou_f_a_2,3) }}</td>
                  <td class="text-center">{{ CheckNum(item.ou_f_b_2,3) }}</td>

                  <td class="text-center">{{ CheckNum(item.hdp_h_2,2) }}</td>
                  <td class="text-center">{{ CheckNum(item.hdp_h_a_2,3) }}</td>
                  <td class="text-center">{{ CheckNum(item.hdp_h_b_2,3) }}</td>
                  <td class="text-center">{{ CheckNum(item.ou_step_h_2,2) }}</td>
                  <td class="text-center">{{ CheckNum(item.ou_step_h_a_2,3) }}</td>
                  <td class="text-center">{{ CheckNum(item.ou_step_h_b_2,3) }}</td>
                </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-overlay :value="loading" :absolute="true">
          <v-progress-circular
            indeterminate
            size="64"
          ></v-progress-circular>
        </v-overlay>
    </v-card>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      req: {},
      num: 10,
      intervalid1: "",
      loading: true,
      items:[],
      date:'',
    };
  },
  methods: {
    GetData(){
      clearInterval(this.intervalid1);
      this.$store.commit("SET_OVERLAY", true);
            this.req
                .get(this.$store.getters.base_api + "GetMuy/"+this.date)
                .then((response) => {
                    this.items = response.data.data;
                    this.num = 10;
                    this.CountDown();
                    this.$store.commit("SET_OVERLAY", false);
                    this.loading = false;
                })
                .catch(() => {
                    setTimeout(function () { 
                      this.GetData();
                    }.bind(this), 3000)
                });
    },
      CountDown(){
        this.intervalid1 = setInterval(function(){
                    if(this.num>0){
                      this.num = this.num - 1;
                    }else{
                      this.loading = true;
                      this.GetData();
                    }
                   }.bind(this), 1000);
    },
    CheckNum(num,f){
      if(isNaN(parseFloat(num))){
        return '';
      }else if(f==3 && parseFloat(num)<1){
        return '';
      }else if(f==2){
        if(parseFloat(num)<0){
          return '';
        }else{
          return num;
        }
      }else{
        return parseFloat(num).toFixed(f);
      }
    },
  },
  mounted() {
    this.req = this.$store.getters.req;
    //console.log(this.CountDown)
    //this.GetData();
  },
};
</script>
<style type="text/css">

  .match_table td{
    font-size: 12px !important;
      font-weight: bold !important;;
  }
  .match_table td{
    border-left: 1px solid #74859c;
      border-bottom: 1px solid #74859c;
  }
  .hdp{
    color: red; 
  }
  .hdp_{
    color: blue;
  }
  .bg2{
    background: #96A7BC;
  }
</style>
<style lang="scss">  
  tbody {
     .league:hover{
      background-color: #000 !important;
     }
     tr:hover {
        background-color: transparent !important;
     }
  }
</style>
